#menu{
    display: flex;
}
.main-parent{
    display: flex !important;
}
.parent {
	display: block;
	position: relative;
	float: left;
	line-height: 30px;
}

.parent a {
	margin: 10px;
	color: #FFFFFF !important;
	text-decoration: none;
}

.parent:hover>ul {
	display: block;
	position: absolute;
}

.child {
	display: none;
}

.child li {
    color: white;
	background-color: #26292c;
	line-height: 30px;
	border-bottom: #CCC 1px solid;
	border-right: #CCC 1px solid;
	width: 100%;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0px;
	min-width: 10em;
    float: right !important;
}

ul ul ul {
	left: 100%;
	top: 0;
	margin-left: 1px;
}

li:hover {
	background-color: #5a5f62;
}

.parent li:hover {
	background-color: #494545;
}

.expand {
	font-size: 12px;
	float: right;
	margin-right: 5px;
}