.hamCheckbox {
    margin-right: 10px !important;
    margin-left: 4px !important;
}

.hamIcon {
    color: #D3A188;
    margin: 8px;
}

.searchIcon {
    font-size: 17px !important;
    margin-left: 4px;
}

.hamSelect {
    background-color: #D3A188 !important;
    color: white !important;
    border-radius: 10px;
    margin-left: 5px;
    background-position: center;
}