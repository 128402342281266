/* WhatsAppButton.css */
.whatsapp-button {
  position: fixed;
  bottom: 30px; /* Adjust the distance from the bottom as needed */
  right: 20px; /* Adjust the distance from the right as needed */
  z-index: 1000; /* Ensure the button appears above other content */
  background-color: #25d366; /* WhatsApp green color */
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.whatsapp-icon {
  width: 40px !important; /* Adjust the size of the WhatsApp icon */
  height: 40px !important; /* Adjust the size of the WhatsApp icon */
  color: white !important;
}
